<template>
  <div class="activity-page">
    <b-card class="" title="Purple Scribe Summary Types">
      <b-btn
        class="float-right"
        style="margin-top: -10px"
        variant="primary"
        @click="$bvModal.show('modal-create-scribe-summary-type')"
      >
        Create Summary Type
      </b-btn>
      <b-card-text>
        This page shows all Purple Scribe summary types.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <div class="row p-2 justify-content-end">
        <section class="col-4">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search..."
              @keydown.enter="getMoreSummaryTypes(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreSummaryTypes(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-4">
          <label>Enterprise</label>
          <enterprise-search-select
            :selected="selectedEnterprise"
            @change="updateSelectedEnterprise"
          />
        </section>

        <div class="ui-spacer" />

        <section class="col-2 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="summaryTypes"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(global)="data">
            {{ data.item.global ? "Yes" : "No" }}
          </template>
          <template #cell(actions)="data">
            <router-link
              :to="{
                name: 'admin-purple-scribe-summary-type-view',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              View summary type
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="totalCount > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="totalCount"
              class="my-0"
              @change="getMoreSummaryTypes"
            />
          </b-col>
          <p v-if="totalCount === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal
      id="modal-create-scribe-summary-type"
      hide-footer
      no-close-on-backdrop
      title="Create scribe summary type"
    >
      <create-scribe-summary-type-modal
        @close="closeModals"
      />
    </b-modal>
  </div>
</template>

<script>
import AdminPurpleScribeService from "@/services/AdminPurpleScribeService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";
import CreateScribeSummaryTypeModal from "@/views/adminPurpleScribe/sections/CreateScribeSummaryTypeModal.vue";

export default {
  name: "AdminPurpleScribeSummaryTypes",
  components: {
    EnterpriseSearchSelect,
    CreateScribeSummaryTypeModal,
  },
  data() {
    return {
      headers: [
        {
          key: "name",
          label: "Name",
          class: "col-4",
        },
        {
          key: "display_name",
          label: "Display Name",
          class: "col-4",
        },
        {
          key: "global",
          label: "Global",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      filters: {
        page: 0,
        page_size: 15,
        search: "",
        enterprise_uid: "",
      },
      summaryTypes: [],
      totalCount: 0,
      currentPage: 1,
      selectedEnterprise: null,
    };
  },
  mounted() {
    this.getSummaryTypes();
  },
  methods: {
    getMoreSummaryTypes(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getSummaryTypes();
    },
    async getSummaryTypes() {
      try {
        let res = await AdminPurpleScribeService.listSummaryTypes(this.filters);
        this.summaryTypes = res.data.data;
        this.filters.page_size = res.data.meta.page_size;
        this.filters.page = res.data.meta.page;
        this.totalCount = res.data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get Purple Scribe summary types, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 15,
        search: "",
      };
      this.selectedEnterprise = null;

      this.getMoreSummaryTypes(1);
    },
    closeModals() {
      this.$bvModal.hide("modal-create-scribe-summary-type");
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.filters.enterprise_uid = "";
      } else {
        this.filters.enterprise_uid = this.selectedEnterprise.uid;
      }
      this.getMoreSummaryTypes(1);
    }
  },
};
</script>

<style lang="scss" scoped></style>
