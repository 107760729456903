<template>
  <section>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(createScribeSummaryType)">
        <b-form-group>
          <!-- Title -->
          <section class="mb-1">
            <label>Name</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true }"
              name="Name"
            >
              <b-form-input
                v-model="summaryType.name"
                :state="getValidationState(validationContext)"
                placeholder="Enter summary type name..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </section>

          <section class="mb-1">
            <label>Display Name</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true }"
              name="Display Name"
            >
              <b-form-input
                v-model="summaryType.display_name"
                :state="getValidationState(validationContext)"
                placeholder="Enter summary type display name..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </section>

          <section class="mb-1">
            <label>Global</label>
            <b-form-checkbox
              v-model="summaryType.global"
              name="check-button"
              switch
            />
          </section>

          <section class="d-flex flex-row-reverse mt-3">
            <b-btn
              :disabled="invalid || loading"
              type="submit"
              variant="primary"
            >
              Create Summary Type
            </b-btn>
            <b-btn
              :disabled="loading"
              class="d-inline mr-2"
              @click="$emit('close')"
            >
              Cancel
            </b-btn>
          </section>
        </b-form-group>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import AdminPurpleScribeService from "@/services/AdminPurpleScribeService";

export default {
  name: "CreateScribeSummaryTypeModal",
  data() {
    return {
      summaryType: {
        name: "",
        display_name: "",
        global: false,
      },
      loading: false,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createScribeSummaryType() {
      this.loading = true;
      try {
        const summaryType = await AdminPurpleScribeService.createSummaryType(this.summaryType);
        this.$toast.success("Summary type created successfully.", {
          toastClassName: ["toast-std", "success-toast"],
        });
        this.$router.push({ name: "admin-purple-scribe-summary-type-view", params: {id: summaryType.data.id} });
        this.$emit("summary-type-created");
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could create Purple Scribe summary type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
